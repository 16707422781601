<template>
  <div>
    <center>
      <q-img
        alt="not-found"
        height="400px"
        src="https://images.unsplash.com/photo-1572827137848-4d7955eb93d6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80"
      />

      <div class="flex justify-center">
        <h4 v-if="$q.platform.is.mobile">404 Not Found</h4>
        <h3 v-else>404 Not Found</h3>
      </div>
    </center>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>
